import React from "react";

//TODO: Fix Componentn, then fix Linting.
/* eslint-disable react/prop-types */
export default function MdxDiv({style, className, children}) {
    return (
        <div className={className} style={style}>
            {children}
        </div>
    );
}
