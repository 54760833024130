/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import MdxDiv from "../components/MdxDiv.js";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(MdxDiv, {
    className: "impressum"
  }, React.createElement("p", null, React.createElement(_components.p, null, "eduFlux GmbH ", React.createElement("br"), "\nFriedrich-Ebert-Straße 55", React.createElement("br"), "\n42103 Wuppertal")), React.createElement("p", null, React.createElement("a", {
    href: "mailto:info@eduflux.de"
  }, "info@eduflux.de"), React.createElement("br"), "\n", React.createElement("a", {
    href: "tel:+49-2336-9242360"
  }, "02336-9242360")), React.createElement("p", null, "Geschäftsführer: Dr. Nikolas List"), "\n", React.createElement("p", null, "Amtsgericht Wuppertal B34271"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
